const DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

function timeToDouble(timeAsString) {
  let hour = parseTime(timeAsString, "hour");
  let minute = parseTime(timeAsString, "minute");
  return hour + minute / 60;
}

function deltaDay(targetDay, day) {
  let targetDayIndex = DAYS.indexOf(targetDay);
  let dayIndex = DAYS.indexOf(day);
  if (targetDayIndex < dayIndex) {
    return 7 - dayIndex + targetDayIndex;
  } else {
    return targetDayIndex - dayIndex;
  }
}

// Helper method for parsing either the hour or minutes
// of a formatted time string
function parseTime(value, index) {
  const time = value.split(":");
  const hour = parseInt(time[0]);
  const minute = parseInt(time[1]);
  const timeObj = {
    hour,
    minute,
  };
  return timeObj[index];
}

// Helper method for sorting 2 work shifts by days
function sortWorkDayFunction(a, b) {
  let sorter = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };
  // if the combination is "sunday" and "monday", "sunday" comes first
  // else use the sorter array
  if (a === "sunday" && b === "monday") {
    return -1;
  } else if (a === "monday" && b === "sunday") {
    return 1;
  } else {
    return sorter[a] - sorter[b];
  }
}

// Helper method for sorting the work hours
function sortWorkHourFunction(a, b) {
  let a_end_hour = parseTime(a.endTime, "hour");
  let a_end_minute = parseTime(a.endTime, "minute");
  let b_end_hour = parseTime(b.endTime, "hour");
  let b_end_minute = parseTime(b.endTime, "minute");

  // if the two work hours start and end on the same day, sort by time
  if (a.startDay === b.startDay && a.endDay === b.endDay) {
    if (a_end_hour === b_end_hour) {
      // sort by minutes
      return a_end_minute - b_end_minute;
    } else {
      // sort by hour
      return a_end_hour - b_end_hour;
    }
  } else if (a.startDay !== b.startDay) {
    // sort by start day
    return sortWorkDayFunction(a.startDay, b.startDay);
  } else {
    // sort by end day
    return sortWorkDayFunction(a.endDay, b.endDay);
  }
}

// Helper method for determining if a work shift is to be truncated or not
function truncateWorkHours(data, day, base_ticker) {
  const min = base_ticker;
  const max = 24 + base_ticker;

  data.forEach((block) => {
    // Keep the original WorkShift start & end (for display purpose)
    block.workHours = {
      startDay: block.startDay,
      startTime: block.startTime,
      endDay: block.endDay,
      endTime: block.endTime,
    };
    // convert endTime if start and end date are different
    let endTime = timeToDouble(block.endTime);
    let startTime = timeToDouble(block.startTime);
    if (block.endDay !== day) {
      endTime = 24 * deltaDay(block.endDay, day) + endTime;
      if (endTime > max) {
        block.truncated = "right";
        block.endTime = convertTimeToString(base_ticker, 0);
        block.endDay = DAYS[(DAYS.indexOf(day) + 1) % 7];
      }
    } else if (block.startDay !== day) {
      startTime = startTime - 24;
      if (startTime < min) {
        block.truncated = "left";
        block.startTime = convertTimeToString(base_ticker, 0);
      }
    } else if (endTime > max) {
      block.truncated = "right";
      block.endTime = convertTimeToString(base_ticker, 0);
    } else if (startTime < min) {
      block.truncated = "left";
      block.startTime = convertTimeToString(base_ticker, 0);
    }
  });
  return data;
}

// Helper method for converting an integer to formatted string
// ex. 5 --> 05:00
// Mainly used for converting the base ticker to formatted time
// for truncated work hours
function convertTimeToString(hour, minute) {
  let strHour = `${hour}`;
  strHour = strHour.length > 1 ? strHour : `0${strHour}`;
  let strMinute = `${minute}`;
  strMinute = strMinute.length > 1 ? strMinute : `0${strMinute}`;
  return `${strHour}:${strMinute}`;
}

// Helper method that cleans the blocks and deletes properties that won't be used.
// Also deletes blocks that start and end on the same time.
// (These blocks can get created during the formatting)
function cleanItems(data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].startTime === data[i].endTime && data[i].startDay === data[i].endDay && data[i].workShiftId) {
      // delete any blocks that start and end at the same time
      data.splice(i, 1);
      i--;
    } else {
      delete data[i].startDay;
      delete data[i].endDay;
      delete data[i].productionUnitName;
      delete data[i].productionUnitId;
      data[i].label = data[i].workShiftName;
      delete data[i].workShiftName;
    }
  }
  return data;
}

// Helper method for calculating the start percentage based on the
// value of the base ticker
function getStartPercentage(base_ticker, block, day) {
  let start = timeToDouble(block.startTime);
  if (block.startDay !== day) start = 24 + start;

  // shift time
  let percentage = ((start - base_ticker) / 24) * 100;
  return parseInt(percentage);
}

// Helper method for calculating the end percentage based on the
// value of the base ticker
function getEndPercentage(base_ticker, block, day) {
  let end = timeToDouble(block.endTime);
  if (block.endDay !== day) end = 24 + end;

  // shift time
  let percentage = ((end - base_ticker) / 24) * 100;
  return parseInt(percentage);
}

function isNextDayBeforeTicker(test_day, test_time, day, base_ticker) {
  if (DAYS.indexOf(test_day) === DAYS.indexOf(day) + 1 && test_time < base_ticker) return true;
  if (day === "sunday") {
    if (test_day === "monday" && test_time < base_ticker) return true;
  }
}

function isWithinDay(start_day, end_day, start_time, end_time, day, base_ticker) {
  if (start_day === day && start_time >= base_ticker) return true;
  if (end_day === day && end_time > base_ticker) return true;
  if (isNextDayBeforeTicker(start_day, start_time, day, base_ticker)) return true;
  if (start_day === day && start_time < base_ticker && isNextDayBeforeTicker(end_day, end_time, day, base_ticker))
    return true;
  return false;
}

// Main helper method that calculates and formats the blocks for a specific day
// for a specific production unit based on a base ticker value.
function getBlocks(pu_name, day, base_ticker, data) {
  // make a shallow copy so that changes to an object
  // isn't propogated in the original data.
  let tempData = [];
  data.forEach((item) => {
    tempData.push({ ...item });
  });

  // filter the data to fetch only the work shifts with work hours
  // for the production unit and within the day provided
  let blocks = tempData.filter((work_hour) => {
    if (!work_hour.workShiftId) return false;

    let end_day = work_hour.endDay;
    let start_day = work_hour.startDay;
    let end_time = timeToDouble(work_hour.endTime);
    let start_time = timeToDouble(work_hour.startTime);
    let production_unit_name = work_hour.productionUnitName;

    return production_unit_name === pu_name && isWithinDay(start_day, end_day, start_time, end_time, day, base_ticker);
  });

  // order the work hours
  blocks.sort(sortWorkHourFunction);

  // determine the truncated work hours
  blocks = truncateWorkHours(blocks, day, base_ticker);

  // determine start and end percentages of valid blocks
  blocks.forEach((block) => {
    block.startPercent = block.truncated === "left" ? 0 : getStartPercentage(base_ticker, block, day);
    block.endPercent = block.truncated === "right" ? 100 : getEndPercentage(base_ticker, block, day);
  });

  // determine start and end percentages of null blocks
  let current_start_percent = 0;
  for (let i = 0; i < blocks.length; i++) {
    let current_block_start_percent = blocks[i].startPercent;
    let current_block_end_percent = blocks[i].endPercent;
    if (current_block_start_percent > current_start_percent) {
      // add a null block from the current start percent to this block's start percent
      blocks.splice(i, 0, {
        startPercent: current_start_percent,
        endPercent: current_block_start_percent,
        workShiftId: null,
      });
      i++;
    }
    current_start_percent = current_block_end_percent;
  }
  // add a last null block if not at 100 percent
  if (current_start_percent < 100) {
    blocks.push({
      startPercent: current_start_percent,
      endPercent: 100,
      workShiftId: null,
    });
  }

  // clean unneeded properties
  return cleanItems(blocks);
}

function withColors(blocks) {
  const size = 8;
  let map = new Map();
  let currentColor = 0;

  blocks.forEach((block) => {
    if (!block.workShiftId) return;

    if (Array.from(map.keys()).includes(block.workShiftId)) {
      block.colorId = map.get(block.workShiftId);
    } else {
      block.colorId = (currentColor % size) + 1;
      map.set(block.workShiftId, block.colorId);
      currentColor++;
    }
  });
  return blocks;
}

// Helper method for flattening the work hours array
function flattenData(data) {
  let tempData = [];

  data.forEach((productionLine) => {
    if (productionLine.work_shifts.length === 0) {
      tempData.push({
        productionUnitName: productionLine.production_unit_name,
        productionUnitId: productionLine.production_unit_id,
      });
    } else {
      productionLine.work_shifts.forEach((workShift) => {
        workShift.work_hours.forEach((workHour) => {
          let recurringDowntimes = [];
          if (workHour.recurring_downtimes && workHour.recurring_downtimes.length > 0) {
            recurringDowntimes = workHour.recurring_downtimes.map((d) => ({
              startDay: d.start_day,
              startTime: d.start_time,
              endDay: d.end_day,
              endTime: d.end_time,
              downtimeReason: d.downtime_reason,
            }));
          }
          tempData.push({
            workShiftId: workShift.id,
            workShiftName: workShift.name,
            productionUnitId: productionLine.production_unit_id,
            productionUnitName: productionLine.production_unit_name,
            startDay: workHour.start_day,
            startTime: workHour.start_time,
            endDay: workHour.end_day,
            endTime: workHour.end_time,
            recurringDowntimes: recurringDowntimes,
          });
        });
      });
    }
  });
  return tempData;
}

function formatData(data, base_ticker) {
  withColors(data);
  let production_unit_schedules = [];
  // get the distinct production unit names
  const distinct_pu_names = getProductionUnitNames(data);
  distinct_pu_names.forEach((pu_name) => {
    let schedule = {};
    schedule.productionUnitName = pu_name;
    let schedule_days = [];
    DAYS.forEach((day) => {
      let schedule_day = {};
      schedule_day.day = day;
      schedule_day.blocks = getBlocks(pu_name, day, base_ticker, data);
      schedule_days.push(schedule_day);
    });
    schedule.days = schedule_days;
    production_unit_schedules.push(schedule);
  });
  return production_unit_schedules;
}

function getProductionUnitNames(data) {
  return [...new Set(data.map((item) => item.productionUnitName))];
}

function getWorkShiftNames(data) {
  let names = new Set();
  data.forEach((puLine) => {
    puLine.days.forEach((day) => {
      day.blocks.forEach((block) => {
        if (block.workShiftId) names.add(block.label);
      });
    });
  });
  return [...names];
}

function getWeekDays(data) {
  let days = new Set();
  data.forEach((puLine) => {
    puLine.days.forEach((day) => {
      if (day.blocks.length > 1) days.add(day.day);
    });
  });
  return [...days];
}

function formatWithSubBlocks(blocks) {
  blocks.forEach((productionUnitWorkShiftBlocks) => {
    productionUnitWorkShiftBlocks.days.forEach((puWorkShiftDay) => {
      puWorkShiftDay.blocks.forEach((dayBlock) => {
        if (dayBlock.recurringDowntimes && dayBlock.recurringDowntimes.length > 0) {
          addSubBlocks(dayBlock);
        }
      });
    });
  });
  return blocks;
}

function addSubBlocks(fullOrTruncatedBlock) {
  const blockStartTime = timeToDouble(fullOrTruncatedBlock.startTime);
  const blockEndTime = fullOrTruncatedBlock.truncated === "right" ? 24 : timeToDouble(fullOrTruncatedBlock.endTime);
  const blockDuration = blockStartTime !== blockEndTime ? blockEndTime - blockStartTime : 24;
  let day = fullOrTruncatedBlock.workHours.startDay;
  if (fullOrTruncatedBlock.truncated === "left") {
    day = fullOrTruncatedBlock.workHours.endDay;
  }

  let subBlocks = [];
  fullOrTruncatedBlock.recurringDowntimes.forEach((d) => {
    let downtimeBlockStartTime = timeToDouble(d.startTime);
    let downtimeBlockEndTime = timeToDouble(d.endTime);
    let downtimeBlockLabel = d.downtimeReason.name + ", " + d.startTime + " — " + d.endTime;
    if (d.startDay !== d.endDay) {
      // Downtime starts and ends on different days
      downtimeBlockEndTime = 24 * deltaDay(d.endDay, day) + downtimeBlockEndTime;
      if (fullOrTruncatedBlock.truncated === "right") {
        let endPercent = 100;
        let startPercent = 100 - parseInt((100 * (24 - downtimeBlockStartTime)) / blockDuration);
        if (startPercent === endPercent) {
          if (startPercent > 0) {
            startPercent = startPercent - 1;
          } else {
            endPercent = endPercent + 1;
          }
        }
        subBlocks.push({
          startPercentWithin: startPercent,
          endPercentWithin: endPercent,
          label: downtimeBlockLabel,
        });
      } else if (fullOrTruncatedBlock.truncated === "left") {
        downtimeBlockEndTime = timeToDouble(d.endTime);
        let startPercent = 0;
        let endPercent = parseInt((100 * downtimeBlockEndTime) / blockDuration);
        if (startPercent === endPercent) {
          if (startPercent > 0) {
            startPercent = startPercent - 1;
          } else {
            endPercent = endPercent + 1;
          }
        }
        subBlocks.push({
          startPercentWithin: startPercent,
          endPercentWithin: endPercent,
          label: downtimeBlockLabel,
        });
      }
    } else if (d.startDay === day) {
      // Downtime starts and ends on the same days
      downtimeBlockStartTime = timeToDouble(d.startTime);
      downtimeBlockEndTime = timeToDouble(d.endTime);
      let startPercent = parseInt((100 * (downtimeBlockStartTime - blockStartTime)) / blockDuration);
      let endPercent = parseInt((100 * (downtimeBlockEndTime - blockStartTime)) / blockDuration);
      if (startPercent === endPercent) {
        if (startPercent > 0) {
          startPercent = startPercent - 1;
        } else {
          endPercent = endPercent + 1;
        }
      }
      subBlocks.push({
        startPercentWithin: startPercent,
        endPercentWithin: endPercent,
        label: downtimeBlockLabel,
      });
    }
  });
  subBlocks.sort(sortByStartPercent);
  fullOrTruncatedBlock["subBlocks"] = subBlocks;
  return fullOrTruncatedBlock;
}

function sortByStartPercent(block1, block2) {
  if (block1.startPercentWithin < block2.startPercentWithin) return -1;
  if (block1.startPercentWithin > block2.startPercentWithin) return 1;
  return 0;
}

class ShiftScheduleFormatter {
  getFormattedSchedules(data, baseTicker) {
    // default base_ticker of 5 if none provided
    let formattedBlocks = formatData(flattenData(data), baseTicker || baseTicker === 0 ? baseTicker : 5);
    return formatWithSubBlocks(formattedBlocks);
  }
  getProductionUnitNames(data) {
    return getProductionUnitNames(flattenData(data));
  }
  getWorkShiftNames(data) {
    return getWorkShiftNames(data);
  }
  getWeekDays(data) {
    return getWeekDays(data);
  }
}

export default new ShiftScheduleFormatter();
