<template>
  <div v-if="activeFactory" class="mb-9">
    <h4 class="font-weight-regular">{{ $t("shiftSchedule.productionDayText") }}</h4>
    <div class="d-flex align-start flex-column-gap my-2">
      <wx-time-picker
        v-model="currentProductionDayStartTime"
        :title="timeLabel"
        :rules="[]"
        :error-messages="[]"
        :show-prepend-icon="false"
        hide-details
      />
      <wx-btn-standard
        v-if="isProductionDayStartTimeDifferent"
        @click="onApply"
        :title="$t('shiftSchedule.productionDayHint')"
        color="primary"
        x-large
      >
        {{ $t("common.applyChanges") }}
      </wx-btn-standard>
    </div>
    <wx-alert-query-message
      :text="$t('shiftSchedule.productionDayHint')"
      container-css-class="mt-1"
      text-css-class="wx-typo-xs"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FactoryService from "@/components/factory/FactoryService";
import WxAlertQueryMessage from "@/components/ui/WxAlertQueryMessage";
import WxBtnStandard from "@/components/ui/WxBtnStandard.vue";
import WxTimePicker from "@/components/ui/WxTimePicker.vue";

export default {
  name: "ProductionDayStartTime",
  components: {
    WxAlertQueryMessage,
    WxBtnStandard,
    WxTimePicker,
  },
  data() {
    return {
      initialProductionDayStartTime: "",
      currentProductionDayStartTime: "",
    };
  },
  watch: {
    activeFactory() {
      this.setStartTime();
      this.emitBaseTicker();
    },
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory"]),
    isProductionDayStartTimeDifferent() {
      return this.initialProductionDayStartTime !== this.currentProductionDayStartTime;
    },
    timeLabel() {
      return this.$t("dashboard.manualRejectsEntry.time");
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationSuccess", "handleHttpError"]),
    ...mapActions("navigation", ["setActiveFactory"]),
    convertToStartTime(minutesFromMidnight) {
      const formatter = new Intl.NumberFormat("en", { minimumIntegerDigits: 2 });
      const hour = formatter.format(parseInt(minutesFromMidnight / 60));
      const minute = formatter.format(parseInt(minutesFromMidnight % 60));
      return `${hour}:${minute}`;
    },
    convertToMinutesFromMidnight() {
      if (!this.currentProductionDayStartTime) return 0;
      const deconstructedTime = this.currentProductionDayStartTime.split(":");
      const hours = parseInt(deconstructedTime[0]);
      const minutes = parseInt(deconstructedTime[1]);
      return hours * 60 + minutes;
    },
    setStartTime() {
      if (this.activeFactory) {
        const minutesFromMidnight = this.activeFactory.productionDayMinutesFromMidnight;
        if (minutesFromMidnight !== null && minutesFromMidnight !== undefined) {
          this.currentProductionDayStartTime = this.convertToStartTime(minutesFromMidnight);
          this.initialProductionDayStartTime = this.convertToStartTime(minutesFromMidnight);
        }
      }
    },
    onApply() {
      this.initialProductionDayStartTime = this.currentProductionDayStartTime;
      const minutesFromMidnight = this.convertToMinutesFromMidnight();
      const update = {
        name: this.activeFactory.name,
        timezone: this.activeFactory.timezone,
        primary_language: this.activeFactory.primaryLanguage,
        year_first_month: this.activeFactory.yearFirstMonth,
        week_first_day: this.activeFactory.weekFirstDay,
        objectives: this.activeFactory.factoryObjectives.map((o) => ({ objective_type: o.objective, value: o.value })),
        currency: this.activeFactory.currency,
        production_day_minutes_from_midnight: minutesFromMidnight,
      };
      FactoryService.updateFactory(this.activeFactory.id, update)
        .then(() => {
          this.showOperationSuccess(
            this.$t("shiftSchedule.productionDayStartTimeUpdated", { factoryName: this.activeFactory.name }),
          );
          this.updateFactoryInStore(minutesFromMidnight);
          this.emitBaseTicker();
        })
        .catch(this.handleHttpError);
    },
    updateFactoryInStore(minutesFromMidnight) {
      let factory = this.activeFactory;
      factory.productionDayMinutesFromMidnight = minutesFromMidnight;
      factory.isCalendarDayBusinessDay = minutesFromMidnight < 720;
      this.setActiveFactory({ activeFactory: factory });
    },
    emitBaseTicker() {
      const hour = parseInt(this.convertToMinutesFromMidnight() / 60);
      this.$emit("production-day-start-time-updated", hour);
    },
  },
  mounted() {
    this.setStartTime();
    this.emitBaseTicker();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .wx-time-picker {
  width: 100%;
  max-width: 160px;
}
</style>
