<template>
  <section class="schedule-timeline-tics" :class="setTicNumbersVisibility()">
    <span class="col-item row-title"></span>
    <ol class="columns">
      <li class="col-item" v-for="thisDayTic in thisDayHours" :key="'li1' + thisDayTic">
        <time class="this-day" :class="'h' + (thisDayTic + nextDayValue)">
          <span :class="`tic-number ${isActive(thisDayTic + nextDayValue, currentHour, false)}`.trim()">{{
            thisDayTic + nextDayValue
          }}</span>
        </time>
      </li>
      <li class="col-item" v-for="nextDayTic in nextDayHours" :key="'li2' + nextDayTic">
        <time class="next-day" :class="'h' + (nextDayTic - 1)">
          <!-- minus 1 because midnight equal `0` -->
          <span :class="`tic-number ${isActive(nextDayTic - 1, currentHour, true)}`.trim()">{{ nextDayTic - 1 }}</span>
        </time>
      </li>
    </ol>
  </section>
</template>
<script>
export default {
  name: "ScheduleTimelineTics",
  data() {
    return {
      currentHour: null,
    };
  },
  props: {
    hideTicNumbers: {
      type: Boolean,
      default: false,
    },
    setFirstTicNumber: {
      type: String,
      default: "5",
      validator(value) {
        return Number(value) < 24;
      },
    },
  },
  computed: {
    getFirstTicNumber() {
      return Number(this.setFirstTicNumber);
    },
    thisDayHours() {
      const hrValue = Number(this.getFirstTicNumber);
      return 24 - hrValue;
    },
    nextDayValue() {
      return 24 - this.thisDayHours - 1;
    },
    nextDayHours() {
      return 24 - this.thisDayHours + 1;
    },
  },
  methods: {
    setTicNumbersVisibility() {
      return this.hideTicNumbers ? "hide-tic-numbers" : false;
    },
    getCurrentHour() {
      const today = new Date();
      const hour = parseInt(today.getHours());
      this.currentHour = hour;
    },
    isActive(ticNumber, currentHour, isNextDay) {
      if (currentHour < this.getFirstTicNumber && isNextDay && ticNumber === currentHour) return "active";
      if (currentHour >= this.getFirstTicNumber && !isNextDay && ticNumber === currentHour) return "active";
      return "";
    },
  },
  mounted() {
    this.getCurrentHour();
    setInterval(this.getCurrentHour, 1000);
  },
};
</script>

<style lang="scss" scoped>
// locla var
$schedule-hours-xs-dimension: 12px;
$schedule-hours-lg-dimension: 16px;
// style
.schedule-timeline-tics {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding-bottom: 0.5rem;

  // <ol>
  .columns {
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  // <li>
  .col-item {
    min-width: $schedule-hours-xs-dimension;
    text-align: center;
    font-size: var(--font-size-xs);

    &.row-title {
      padding: 0;
    }

    time {
      display: block;
      line-height: 1;

      // &.this-day {
      //   color: var(--color-text-subtle-theme);
      // }
      // &.next-day {
      //   color: var(--color-text-theme);
      // }

      .tic-number {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        width: $schedule-hours-xs-dimension;
        color: var(--color-text-subtle-theme);
        background-color: transparent;

        &.active {
          color: var(--color-primary);
          font-weight: 700; /* bold */
        }
      }

      /* display dots for testing vertical alignment while developing */
      // &::after {
      //   content: "\2022";
      //   display: block;
      // }
    }
  }
  // responsive
  @media ($wx-lg-min) {
    .col-item {
      min-width: $schedule-hours-lg-dimension;

      time .tic-number {
        width: $schedule-hours-lg-dimension;
      }
    }
  }
  &.hide-tic-numbers {
    padding-bottom: 0;
    // <li>
    .col-item {
      time {
        line-height: 0;

        .tic-number {
          display: none;
        }
      }
    }
  }
}
</style>
