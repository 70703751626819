<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ $t("mainNav.shiftSchedules") }}</h1>

    <shift-schedules />
  </article>
</template>

<script>
import ShiftSchedules from "@/components/shiftscheduler/ShiftSchedules";

export default {
  name: "ShiftSchedulesView",
  components: {
    ShiftSchedules,
  },
};
</script>
