<template>
  <fragment>
    <production-day-start-time v-if="showProductionDayDropdown" />
    <header v-if="!isMobilePhone" class="page-header" :class="{ 'filtered-chips': isFilterActive }">
      <!--
        Tablet devices
        -->
      <v-form v-if="isMobileTablet" class="filter-form multi-row-filter">
        <v-row>
          <v-col class="top-action">
            <wx-btn-standard
              x-large
              color="primary"
              class="mb-2"
              :to="toShiftCreation()"
              :title="$t('shiftSchedule.filters.filteredNewShiftHoverTitle')"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t("shiftSchedule.filters.newShiftButton") }}
            </wx-btn-standard>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <fieldset class="row filters">
              <v-col>
                <wx-autocomplete
                  v-model="productionLineFilter"
                  :items="productionUnitNames"
                  :label="$t('shiftSchedule.filters.allLinesOption')"
                  clearable
                  multiple
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars -->
                  <template v-slot:selection="{ item, index }">
                    <span class="active-filter-counter" v-if="index === 0">
                      {{ `${productionLineFilter.length}` }}
                      <span class="string">{{ $t("shiftSchedule.filters.activeFilterLabel") }}</span>
                    </span>
                  </template>
                </wx-autocomplete>
              </v-col>
              <v-col>
                <wx-select
                  :label="$t('shiftSchedule.filters.allDaysOption')"
                  v-model="dayFilter"
                  :items="weekdays"
                  :menu-props="{ offsetY: true }"
                  clearable
                  multiple
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars -->
                  <template v-slot:selection="{ item, index }">
                    <span class="active-filter-counter" v-if="index === 0">
                      {{ `${dayFilter.length}` }}
                      <span class="string">{{ $t("shiftSchedule.filters.activeFilterLabel") }}</span>
                    </span>
                  </template>
                </wx-select>
              </v-col>
              <v-col>
                <wx-autocomplete
                  :label="$t('shiftSchedule.filters.allShiftsOption')"
                  v-model="workShiftFilter"
                  :items="workShiftNames"
                  clearable
                  multiple
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars -->
                  <template v-slot:selection="{ item, index }">
                    <span class="active-filter-counter" v-if="index === 0">
                      {{ `${workShiftFilter.length}` }}
                      <span class="string">{{ $t("shiftSchedule.filters.activeFilterLabel") }}</span>
                    </span>
                  </template>
                </wx-autocomplete>
              </v-col>
            </fieldset>
          </v-col>
        </v-row>
      </v-form>
      <!--
        Desktop devices
        -->
      <v-form v-if="!isMobileTablet" class="filter-form single-row-filter">
        <v-row>
          <v-col>
            <fieldset class="filters">
              <wx-autocomplete
                v-model="productionLineFilter"
                :items="productionUnitNames"
                :label="$t('shiftSchedule.filters.allLinesOption')"
                clearable
                multiple
              >
                <!-- eslint-disable-next-line vue/no-unused-vars -->
                <template v-slot:selection="{ item, index }">
                  <span class="active-filter-counter" v-if="index === 0">
                    {{ `${productionLineFilter.length}` }}
                    <span class="string">{{ $t("shiftSchedule.filters.activeFilterLabel") }}</span>
                  </span>
                </template>
              </wx-autocomplete>
              <wx-select
                v-model="dayFilter"
                :items="weekdays"
                :label="$t('shiftSchedule.filters.allDaysOption')"
                :menu-props="{ offsetY: true }"
                clearable
                multiple
              >
                <!-- eslint-disable-next-line vue/no-unused-vars -->
                <template v-slot:selection="{ item, index }">
                  <span class="active-filter-counter" v-if="index === 0">
                    {{ `${dayFilter.length}` }}
                    <span class="string">{{ $t("shiftSchedule.filters.activeFilterLabel") }}</span>
                  </span>
                </template>
              </wx-select>
              <wx-autocomplete
                v-model="workShiftFilter"
                :items="workShiftNames"
                :label="$t('shiftSchedule.filters.allShiftsOption')"
                clearable
                multiple
              >
                <!-- eslint-disable-next-line vue/no-unused-vars -->
                <template v-slot:selection="{ item, index }">
                  <span class="active-filter-counter" v-if="index === 0">
                    {{ `${workShiftFilter.length}` }}
                    <span class="string">{{ $t("shiftSchedule.filters.activeFilterLabel") }}</span>
                  </span>
                </template>
              </wx-autocomplete>
            </fieldset>

            <wx-btn-standard
              x-large
              color="primary"
              class="mb-2"
              :to="toShiftCreation()"
              :title="$t('shiftSchedule.filters.filteredNewShiftHoverTitle')"
            >
              <v-icon left>mdi-plus</v-icon>
              {{ $t("shiftSchedule.filters.newShiftButton") }}
            </wx-btn-standard>
          </v-col>
        </v-row>
      </v-form>
    </header>
    <!--
      Phone devices
      -->
    <v-sheet v-if="!isMobilePhone" tag="section" class="page-body" :class="{ 'filtered-chips': isFilterActive }">
      <v-alert type="warning" icon="$alertIcon" class="nodata" outlined text v-if="!this.productionUnitNames">
        <h3>{{ $t("shiftSchedule.noData.noShift") }}</h3>
        <p>{{ $t("shiftSchedule.noData.noShiftHoverTitle") }}</p>
        <wx-btn-standard :to="toShiftCreation()">
          <v-icon left>mdi-plus</v-icon>
          {{ $t("shiftSchedule.filters.newShiftButton") }}
        </wx-btn-standard>
      </v-alert>
      <v-expansion-panels id="production-lines" v-model="panel" multiple focusable flat v-if="this.productionUnitNames">
        <v-row v-if="isFilterActive" class="filtered-chips-container">
          <v-chip-group column>
            <div class="filter-group lines-chips">
              <v-chip
                close
                class="filtered-chip"
                color="primary"
                small
                @click:close="removeProductionLineSelection(puName)"
                v-for="puName in productionLineFilter"
                :key="puName"
              >
                {{ puName }}
              </v-chip>
            </div>
            <div class="filter-group days-chips">
              <v-chip
                close
                class="filtered-chip days-chip"
                color="primary"
                small
                @click:close="removeDaySelection(day)"
                v-for="day in dayFilter"
                :key="day"
              >
                {{ getDay(day) }}
              </v-chip>
            </div>
            <div class="filter-group shifts-chips">
              <v-chip
                close
                class="filtered-chip"
                color="primary"
                small
                @click:close="removeWorkShiftSelection(wsName)"
                v-for="wsName in workShiftFilter"
                :key="wsName"
              >
                {{ wsName }}
              </v-chip>
            </div>
          </v-chip-group>
        </v-row>
        <schedule-timeline-tics :set-first-tic-number="baseTickerToString" />

        <v-alert
          type="warning"
          icon="$alertIcon"
          class="nodata"
          outlined
          text
          v-if="this.productionUnitSchedulesFormatted.length === 0 && !this.dataLoading"
        >
          <h3>{{ $t("shiftSchedule.noData.noFilteredShift") }}</h3>
          <p>{{ $t("shiftSchedule.noData.noShiftHoverTitle") }}</p>
          <wx-btn-standard color="primary" :to="toShiftCreation()">
            <v-icon left>mdi-plus</v-icon>
            {{ $t("shiftSchedule.filters.newShiftButton") }}
          </wx-btn-standard>
        </v-alert>
        <v-expansion-panel
          class="week-schedule"
          v-for="(puSchedule, index) in this.productionUnitSchedulesFormatted"
          :key="index"
          :disabled="isFilterActive"
        >
          <v-expansion-panel-header
            :class="`week-schedule-header ${puSchedule.disabled ? 'pu-disabled' : ''}`.trim()"
            ripple
            :disabled="puSchedule.disabled"
          >
            <span class="no-shift-cta" v-if="isProductionLineScheduleEmpty(puSchedule)">
              <v-chip class="no-shift disabled">{{ $t("shiftSchedule.noData.noShift") }}</v-chip>
              <wx-btn-standard
                :to="toShiftCreation()"
                :title="$t('shiftSchedule.filters.lineFirstShiftHoverTitle')"
                outlined
              >
                <v-icon left>mdi-plus</v-icon>
                {{ $t("shiftSchedule.filters.newShiftButton") }}
              </wx-btn-standard>
            </span>
            <span class="line-name font-weight-bold">
              {{ puSchedule.productionUnitName }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="week-schedule-content">
            <schedule-timeline-tics hide-tic-numbers />
            <div v-if="!isProductionLineScheduleEmpty(puSchedule)">
              <dl class="day-row" v-for="(shiftByDay, dayIndex) in puSchedule.days" :key="dayIndex">
                <!--
                Responsive <dt> @media XS > LG
                -->
                <dt class="row-title wx-typo-sm d-flex d-lg-none" :title="getDay(shiftByDay.day)">
                  {{ getDay(shiftByDay.day) | truncate(1, language) }}
                </dt>
                <!--
                Responsive <dt> @media LG > XL
                -->
                <dt class="row-title wx-typo-sm d-none d-lg-flex d-xl-none" :title="getDay(shiftByDay.day)">
                  {{ getDay(shiftByDay.day) | truncate(3, language) }}
                </dt>
                <!--
                Responsive <dt> @media XL > ∞
                -->
                <dt class="row-title wx-typo-sm d-none d-xl-flex">
                  {{ getDay(shiftByDay.day) }}
                </dt>

                <dd class="row-blocks-container">
                  <template v-for="(block, blockIndex) in shiftByDay.blocks">
                    <wx-btn-standard
                      v-if="block.workShiftId"
                      :class="
                        `block ${block.truncated ? ` truncated-${block.truncated}` : ''} option-pigment${
                          block.colorId
                        }`.trim()
                      "
                      :key="blockIndex"
                      :style="{
                        width: `calc(${block.endPercent - block.startPercent}% - 4px)`,
                      }"
                      :title="getBlockTitle(block)"
                      :disabled="block.disabled"
                      :to="toShiftEdition(block.workShiftId)"
                    >
                      <span v-if="block.workShiftId" class="string wx-typo-sm">{{ block.label }}</span>
                      <time v-if="block.workShiftId" class="digits wx-typo-xs">{{ getBlockStartEndTime(block) }}</time>
                      <fragment v-if="block.recurringDowntimes">
                        <span
                          v-for="(dowtimeBlock, index) in block.subBlocks"
                          :key="`dowtimeBlock-${index}`"
                          :title="[$t('legend.plannedDowntime') + ' : ' + dowtimeBlock.label]"
                          :style="{
                            left: `${dowtimeBlock.startPercentWithin}%`,
                            width: `${getSubBlockWidthValue(dowtimeBlock)}%`,
                          }"
                          class="downtime-subblock recurring"
                        />
                      </fragment>
                    </wx-btn-standard>
                    <div
                      v-if="!block.workShiftId"
                      class="block placeholder"
                      :key="blockIndex"
                      :style="{ width: `${block.endPercent - block.startPercent}%` }"
                    />
                  </template>
                </dd>
              </dl>
            </div>
            <schedule-timeline-tics hide-tic-numbers />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-sheet>

    <v-alert v-if="isMobilePhone" icon="mdi-card-off-outline" class="alert-unsupported-dimension" text>
      <h2 class="alert-title">{{ $t("shiftSchedule.alertResponsive.unsupportedPageSize") }}</h2>
      <p class="wx-typo-norm">{{ $t("shiftSchedule.alertResponsive.smallScreen") }}</p>
      <p class="wx-typo-norm">{{ this.getMinimumViewportWidth }}</p>
      <footer class="alert-options">
        <h3 class="heading">{{ $t("shiftSchedule.alertResponsive.helpHeading") }}</h3>
        <ul>
          <li>
            <v-icon>mdi-laptop</v-icon>
            <aside>
              <p class="wx-typo-norm">{{ $t("shiftSchedule.alertResponsive.helpIfComputer") }}</p>
            </aside>
          </li>
          <li>
            <v-icon>mdi-phone-rotate-landscape</v-icon>
            <aside>
              <p class="wx-typo-norm">{{ $t("shiftSchedule.alertResponsive.helpIfPhone") }}</p>
              <wx-btn-standard color="primary" :title="$t('shiftSchedule.alertResponsive.helpButtonHoverTitle')">
                <v-icon left>mdi-chevron-left</v-icon>
                {{ $t("common.goBack") }}
              </wx-btn-standard>
            </aside>
          </li>
        </ul>
      </footer>
    </v-alert>
  </fragment>
</template>

<script>
import ScheduleTimelineTics from "@/components/shiftscheduler/ScheduleTimelineTics.vue";
import i18n from "@/i18n";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import WxAutocomplete from "@/components/ui/WxAutocomplete";
import WxSelect from "@/components/ui/WxSelect";
import WorkShiftService from "./WorkShiftService";
import ShiftScheduleFormatter from "./ShiftScheduleFormatter";
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";
import DowntimeReasonService from "@/components/downtimereason/DowntimeReasonService";
import { mapActions, mapGetters } from "vuex";
import { DateTime } from "luxon";
import ProductionDayStartTime from "@/components/shiftscheduler/ProductionDayStartTime.vue";
import DemoService from "@/components/DemoService";

export function sortWorkShiftByName(ws1, ws2) {
  if (ws1.productionUnitName < ws2.productionUnitName) {
    return -1;
  }
  if (ws1.productionUnitName > ws2.productionUnitName) {
    return 1;
  }
  return 0;
}

export default {
  name: "ShiftSchedules",
  components: {
    ProductionDayStartTime,
    ScheduleTimelineTics,
    WxBtnStandard,
    WxAutocomplete,
    WxSelect,
  },
  data() {
    return {
      detailsIndex: -1,
      clickedChildState: false,
      baseTicker: 0,
      productionUnitSchedulesRaw: [],
      productionUnitNames: [],
      panel: [0],
      productionLineFilter: [],
      dayFilter: [],
      workShiftFilter: [],

      dataLoading: true,
    };
  },
  computed: {
    ...mapGetters("user", ["theme", "isAdmin", "email", "language"]),
    ...mapGetters("navigation", ["activeFactory", "activeFactoryProductionUnits"]),
    showProductionDayDropdown() {
      return !this.isMobilePhone && this.isAdmin;
    },
    weekdays() {
      let dayList = [
        { value: "monday", text: i18n.t("common.dayNames.monday") },
        { value: "tuesday", text: i18n.t("common.dayNames.tuesday") },
        { value: "wednesday", text: i18n.t("common.dayNames.wednesday") },
        { value: "thursday", text: i18n.t("common.dayNames.thursday") },
        { value: "friday", text: i18n.t("common.dayNames.friday") },
        { value: "saturday", text: i18n.t("common.dayNames.saturday") },
        { value: "sunday", text: i18n.t("common.dayNames.sunday") },
      ];
      if (this.workShiftFilter.length > 0 && this.dayFilter.length === 0) {
        let days = ShiftScheduleFormatter.getWeekDays(this.productionUnitSchedulesFormatted);
        return dayList.filter((item) => {
          if (days.includes(item.value)) return true;
          return false;
        });
      }
      return dayList;
    },
    isMobilePhone() {
      const vbpName = this.$vuetify.breakpoint.name;
      let phoneBreakpoint = false;
      if (vbpName === "xs") {
        phoneBreakpoint = true;
      }
      return phoneBreakpoint;
    },
    isMobileTablet() {
      return this.$vuetify.breakpoint.mobile;
    },
    getMinimumViewportWidth() {
      let minimumViewportValue = this.$vuetify.breakpoint.thresholds.xs;
      return this.$t("shiftSchedule.alertResponsive.minimumViewportWidth", { minimumViewportValue });
    },
    workShiftNames() {
      if (!this.productionUnitSchedulesRaw || this.productionUnitSchedulesRaw.length === 0) return [];
      let filteredData = this.productionUnitSchedulesRaw;
      // filter production lines
      if (this.productionLineFilter.length > 0) {
        filteredData = this.filterProductionLines(filteredData);
      }

      let formattedData = ShiftScheduleFormatter.getFormattedSchedules(filteredData, this.baseTicker);

      // filter week days
      if (this.dayFilter.length > 0) {
        formattedData = this.filterDays(formattedData);
      }

      return ShiftScheduleFormatter.getWorkShiftNames(formattedData).sort();
    },
    baseTickerToString() {
      return `${this.baseTicker}`;
    },
    isFilterActive() {
      return this.productionLineFilter.length > 0 || this.dayFilter.length > 0 || this.workShiftFilter.length > 0;
    },
    productionUnitSchedulesFormatted() {
      // Must be a computed property so that the formatter formats the schedules every time any
      // of the related data changes:
      //     Raw data
      //     Production unit filter
      //     Weekday filter
      //     Work shift filter

      // ShiftScheduleFormatter will re-format the raw data based on the baseTicker every time
      // the baseTicker changes or the raw production unit schedules data change.
      if (!this.productionUnitSchedulesRaw || this.productionUnitSchedulesRaw.length === 0) return [];
      let filteredData = JSON.parse(JSON.stringify(this.productionUnitSchedulesRaw).slice());

      // filter work shifts
      if (this.workShiftFilter.length > 0) {
        filteredData = this.filterWorkShifts(filteredData);
      }
      // filter production lines
      if (this.productionLineFilter.length > 0) {
        filteredData = this.filterProductionLines(filteredData);
      }

      let formattedData = ShiftScheduleFormatter.getFormattedSchedules(filteredData, this.baseTicker);

      // filter week days
      if (this.dayFilter.length > 0) {
        formattedData = this.filterDays(formattedData);
      }

      // if production line schedule is empty AND there are filtered work shifts, remove the empty production schedules
      if (this.workShiftFilter.length > 0) {
        formattedData = this.removeEmptySchedules(formattedData);
      }

      formattedData.forEach((x, index) => x.productionUnitName = DemoService.maskProductionUnitNameIfNecessary(this.email, x.productionUnitName, index + 1));
      return formattedData.sort(sortWorkShiftByName);
    },
  },
  watch: {
    async activeFactory() {
      if (this.activeFactory) {
        this.productionLineFilter = [];
        this.dayFilter = [];
        this.workShiftFilter = [];
        this.productionUnitSchedulesRaw = [];
        this.productionUnitNames = [];
        await this.loadShiftSchedules();
      }
    },
    productionLineFilter() {
      if (this.productionLineFilter !== 0) {
        this.panel = [];
        for (let i = 0; i < this.productionUnitSchedulesFormatted.length; i++) {
          this.panel.push(i);
        }
      }
    },
    workShiftFilter() {
      if (this.workShiftFilter !== 0) {
        this.panel = [];
        for (let i = 0; i < this.productionUnitSchedulesFormatted.length; i++) {
          this.panel.push(i);
        }
      }
    },
    dayFilter() {
      if (this.dayFilter !== 0) {
        this.panel = [];
        for (let i = 0; i < this.productionUnitSchedulesFormatted.length; i++) {
          this.panel.push(i);
        }
      }
    },
    isFilterActive() {
      if (!this.isFilterActive) this.panel = [0];
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    getBlockTitle(block) {
      return [block.label + " (" + block.workHours.startTime + "—" + block.workHours.endTime + ")"];
    },
    getBlockStartEndTime(block) {
      return " (" + block.workHours.startTime + "—" + block.workHours.endTime + ")";
    },
    getSubBlockWidthValue(subBlock) {
      return subBlock.endPercentWithin - subBlock.startPercentWithin;
    },
    removeProductionLineSelection(puName) {
      const index = this.productionLineFilter.indexOf(puName);
      if (index >= 0) this.productionLineFilter.splice(index, 1);
    },
    removeDaySelection(day) {
      const index = this.dayFilter.indexOf(day);
      if (index >= 0) this.dayFilter.splice(index, 1);
    },
    removeWorkShiftSelection(wsName) {
      const index = this.workShiftFilter.indexOf(wsName);
      if (index >= 0) this.workShiftFilter.splice(index, 1);
    },
    isProductionLineScheduleEmpty(puSchedule) {
      let isEmpty = true;
      puSchedule.days.forEach((day) => {
        if (day.blocks.length > 1) {
          isEmpty = false;
        } else if (day.blocks[0].workShiftId) {
          isEmpty = false;
        }
      });
      return isEmpty;
    },
    removeEmptySchedules(data) {
      return data.filter((item) => !this.isProductionLineScheduleEmpty(item));
    },
    filterProductionLines(data) {
      return data.filter((line) => {
        return this.productionLineFilter.includes(line.production_unit_name);
      });
    },
    filterDays(data) {
      data.forEach((line) => {
        line.days = line.days.filter((item) => {
          return this.dayFilter.includes(item.day);
        });
      });
      return data;
    },
    filterWorkShifts(data) {
      return data.filter((item) => {
        item.work_shifts = item.work_shifts.filter((workShift) => {
          return this.workShiftFilter.includes(workShift.name);
        });

        return item.work_shifts.length > 0;
      });
    },
    async loadShiftSchedules() {
      if (this.activeFactory && this.activeFactory.id) {
        await WorkShiftService.getWorkShifts(this.activeFactory.id)
          .then((response) => this.handleShiftScheduleRetrievalResponse(response))
          .catch((error) => this.handleShiftScheduleRetrievalError(error.response));
      }
    },
    async handleShiftScheduleRetrievalResponse(httpResponse) {
      // Get downtime reason definitions
      let reasonById = await this.getDowntimeReasonsById();

      let shifts = httpResponse.data
        .filter((ws) => this.isAssociatedToFactoryPUs(ws))
        .map((ws) => this.convertShift(ws, reasonById));

      this.productionUnitSchedulesRaw = shifts;
      this.productionUnitNames = ShiftScheduleFormatter.getProductionUnitNames(shifts).sort().map((name, index) => DemoService.maskProductionUnitNameIfNecessary(this.email, name, index + 1));
      this.dataLoading = false;
    },
    isAssociatedToFactoryPUs(ws) {
      return this.activeFactoryProductionUnits.find((pu) => pu.id === ws.production_unit_id);
    },
    convertShift(shiftForPU, reasonById) {
      const workShifts = shiftForPU.work_shifts.map((shift) => {
        const workHours = this.convertWorkHours(shift, reasonById);
        return {
          id: shift.id,
          account_id: shift.account_id,
          factory_id: shift.factory_id,
          name: shift.name,
          work_hours: workHours,
        };
      });
      return {
        production_unit_id: shiftForPU.production_unit_id,
        production_unit_name: shiftForPU.production_unit_name,
        work_shifts: workShifts,
      };
    },
    handleShiftScheduleRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.dataLoading = false;
    },
    handleDowntimeReasonTreeRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.dataLoading = false;
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
    getDay(day) {
      switch (day) {
        case "monday":
          return i18n.t("common.dayNames.monday");
        case "tuesday":
          return i18n.t("common.dayNames.tuesday");
        case "wednesday":
          return i18n.t("common.dayNames.wednesday");
        case "thursday":
          return i18n.t("common.dayNames.thursday");
        case "friday":
          return i18n.t("common.dayNames.friday");
        case "saturday":
          return i18n.t("common.dayNames.saturday");
        case "sunday":
          return i18n.t("common.dayNames.sunday");
        default:
          return null;
      }
    },
    async getDowntimeReasonsById() {
      let reasonById = new Map();
      await DowntimeReasonService.getDowntimeReasonTree(this.activeFactory.id)
        .then((httpResponse) => {
          let plannedReasons = [];
          DowntimeReasonService.extractReasonsFromTree(httpResponse.data.children, plannedReasons);
          plannedReasons.forEach((r) => reasonById.set(r.id, r));
        })
        .catch((error) => this.handleDowntimeReasonTreeRetrievalError(error.response));
      return reasonById;
    },
    convertWorkHours(shift, reasonById) {
      return shift.work_hours.map((wh) => {
        let whStart = this.anchorInTime(wh.start_day, wh.start_time, wh.end_day);
        let whEnd = this.anchorInTime(wh.end_day, wh.end_time, wh.end_day);

        const recurringDowntimesForWorkHour = shift.recurring_downtimes.filter((d) => {
          let dStart = this.anchorInTime(d.start_day, d.start_time, wh.end_day);
          let dEnd = this.anchorInTime(d.end_day, d.end_time, wh.end_day);
          return dStart.toMillis() >= whStart.toMillis() && dEnd.toMillis() <= whEnd.toMillis();
        });

        // Update recurring downtime: add the downtime reason definition
        const recurringDowntimesForDisplay = recurringDowntimesForWorkHour
          .filter((d) => reasonById[d.downtime_reason_id] !== null)
          .map((d) => {
            const downtimeReason = reasonById.get(d.downtime_reason_id);
            if (downtimeReason) {
              return {
                start_day: d.start_day,
                start_time: d.start_time,
                end_day: d.end_day,
                end_time: d.end_time,
                downtime_reason: {
                  id: downtimeReason.id,
                  code: downtimeReason.code,
                  name: downtimeReason.name,
                  planned: downtimeReason.planned,
                },
              };
            }
          });

        return {
          start_day: wh.start_day,
          start_time: wh.start_time,
          end_day: wh.end_day,
          end_time: wh.end_time,
          recurring_downtimes: recurringDowntimesForDisplay,
        };
      });
    },
    anchorInTime(startDay, time, endDay) {
      switch (String(startDay).toLowerCase()) {
        case "monday": {
          return DateTime.fromFormat(`2022-07-11 ${time}:00-04:00`, "yyyy-LL-dd HH:mm:ssZZ");
        }
        case "tuesday": {
          return DateTime.fromFormat(`2022-07-12 ${time}:00-04:00`, "yyyy-LL-dd HH:mm:ssZZ");
        }
        case "wednesday": {
          return DateTime.fromFormat(`2022-07-13 ${time}:00-04:00`, "yyyy-LL-dd HH:mm:ssZZ");
        }
        case "thursday": {
          return DateTime.fromFormat(`2022-07-14 ${time}:00-04:00`, "yyyy-LL-dd HH:mm:ssZZ");
        }
        case "friday": {
          return DateTime.fromFormat(`2022-07-15 ${time}:00-04:00`, "yyyy-LL-dd HH:mm:ssZZ");
        }
        case "saturday": {
          return DateTime.fromFormat(`2022-07-16 ${time}:00-04:00`, "yyyy-LL-dd HH:mm:ssZZ");
        }
        case "sunday": {
          if (endDay === "monday") {
            return DateTime.fromFormat(`2022-07-10 ${time}:00-04:00`, "yyyy-LL-dd HH:mm:ssZZ");
          } else {
            return DateTime.fromFormat(`2022-07-17 ${time}:00-04:00`, "yyyy-LL-dd HH:mm:ssZZ");
          }
        }
      }
    },
    toShiftCreation() {
      return RouteService.toShiftCreation();
    },
    toShiftEdition(workShiftId) {
      return RouteService.toShiftEdition(workShiftId);
    },
  },
  async mounted() {
    await this.loadShiftSchedules();
  },
  filters: {
    // eslint-disable-next-line
    truncate: function(text, length, lang) {
      if (text.length >= 1) {
        if (lang === "zh") {
          return length === 1 ? text.charAt(1) : text;
        } else {
          return text.substring(0, length);
        }
      } else {
        return text;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// local var
$schedule_gutters: 4px;
$row_vertical_height: 56px;
$pattern_stroke_width: 3px;
$pattern_space: 6px;

/**
 * Form filters
 */
.filter-form {
  .col {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
  }
  fieldset.filters {
    display: flex;
    //flex-basis: content;
    flex-grow: 1;
    margin: 0 var(--grid-gutter) 0 0;
    padding: 0;
    border: 0 solid var(--color-border-theme);

    ::v-deep .v-input.v-input--dense {
      .v-input__control {
        .v-input__slot {
          border-radius: var(--border-radius-form-elements);

          // hide border-bottom
          &::before,
          &::after {
            display: none;
            border-color: transparent !important;
          }
        }
      }
    }
    .active-filter-counter {
      font-weight: 700; // bold
      .string {
        padding-left: 0.2rem;
        font-size: var(--font-size-sm);
        font-weight: 400;
        color: var(--color-text-subtle-theme);
      }
    }
  }

  &.single-row-filter {
    .col:last-child {
      justify-content: flex-end;
      align-items: stretch;
    }
    ::v-deep .v-input:not(.dropdown-nav) {
      margin-right: var(--grid-gutter);

      // prevent dropdowns from extending width according to selections
      width: 6rem;
      @media ($wx-lg-min) {
        width: 9rem;
      }
      @media ($wx-xl-min) {
        width: 11rem;
      }
    }
  }
  &.multi-row-filter {
    // align newShiftButton with page title on table
    margin-top: -4.8rem;
    ::v-deep .row:first-child {
      margin-bottom: -10px; // designer request to add space below button
    }

    .filters {
      &.row {
        margin-right: 0;
        .col {
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
}

.page-header {
  //transition: var(--smooth-transition);
  padding-bottom: var(--grid-gutter);

  &.filtered-chips {
    padding-bottom: 0;
  }
  // top button container
  .top-action {
    display: flex;
    flex-direction: row-reverse;
  }
  .filters {
    margin-bottom: 1rem;
  }
}

.page-body {
  min-width: $wx-min-width-tablet-vertical;
  overflow: visible;
  overflow-x: auto;

  .filtered-chips-container {
    height: auto;
    margin: 0 0 1rem;
    transition: var(--smooth-transition);

    // spacing between each group
    .filter-group {
      .filtered-chip {
        &:last-child {
          margin-right: var(--grid-gutter);
        }
      }
    }
  }
}

.page-body {
  background-color: transparent;
}
::v-deep .v-expansion-panels {
  .v-expansion-panel.week-schedule {
    background-color: transparent !important;
    transition: var(--smooth-transition);
  }
}

#production-lines {
  ::v-deep .shift-hours-colomns .row-title,
  .v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon > .v-icon {
    display: inline-block;
    width: 3rem;
  }
}

// Over-rule vuetify styles
.week-schedule.v-expansion-panel.v-expansion-panel--next-active,
.week-schedule-header.v-expansion-panel-header--mousedown,
.week-schedule-content > .v-expansion-panel-content__wrap {
  opacity: 1;
}

.week-schedule-header {
  &.v-expansion-panel-header,
  &.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: $row_vertical_height !important;
  }
  &::before {
    background-color: transparent !important;
  }
  &.v-expansion-panel-header--mousedown {
    color: var(--color-primary);
  }
}

// Our mockups
.week-schedule.v-expansion-panel {
  &,
  &.v-expansion-panel--active,
  &.v-expansion-panel--mousedown {
    margin-top: ($schedule_gutters / 2);
    margin-bottom: ($schedule_gutters / 2);
  }
  .week-schedule-header {
    flex-direction: row-reverse;
    min-height: $row_vertical_height;
    padding: 0.2rem;
    border-radius: var(--border-radius-form-elements);
    background-color: var(--color-element-layer1);
    border: 1px solid var(--color-border-theme);

    .no-shift-cta {
      display: flex;
      justify-content: space-between;
      width: 20%;
      .v-chip {
        width: auto;
        height: auto;
        color: var(--color-text-disabled-theme);
        background-color: transparent;
      }
      // this style does NOT follow style guidelines
      .v-btn {
        &.v-btn--outlined:not(.disabled) {
          border-color: var(--color-contrast-theme);
        }
        &.disabled {
          display: none;
        }
      }
    }
    .line-name {
      padding-left: 0.4rem;
      font-weight: 700; /* bold */
    }

    @media ($wx-lg-min) {
      padding-left: 1rem;
      .line-name {
        padding-left: 1rem;
      }
    }
    @media ($wx-xl-min) {
      padding-left: 3.1rem;
      .line-name {
        padding-left: 3.1rem;
      }
    }
  }

  ::v-deep .week-schedule-content > div {
    padding-bottom: 0;
    padding-inline: 0;
  }
  .week-schedule-content {
    background-color: transparent;

    .row-title,
    .row-blocks-container {
      align-items: center;
      min-height: $row_vertical_height;
    }
    .row-title {
      color: var(--color-text-subtle-theme);
      text-transform: capitalize;
      background-color: var(--color-element-layer1);
      border: 1px solid var(--color-border-theme);
    }
    .row-blocks-container {
      background-color: transparent;
    }
  }
}

/**
 * Style days within .week-schedule-content
 */

.day-row {
  display: flex;
  align-items: center;
  position: relative;
  margin: $schedule_gutters 0;
  border-radius: var(--border-radius-form-elements);
}

// first columns are vertically aligned
.row-title,
::v-deep .col-item.row-title {
  clear: left;
}
::v-deep .col-item.row-title {
  clear: left;
  width: 22px;

  // responsive
  @media ($wx-lg-min) {
    width: 48px;
  }
  @media ($wx-xl-min) {
    width: 118px;
  }
}
.row-title {
  display: flex;
  justify-content: center;
  padding: 0.65rem 0;
  border-radius: var(--border-radius-form-elements);
  width: 22px;

  // responsive
  @media ($wx-lg-min) {
    width: 56px;
  }
  @media ($wx-xl-min) {
    width: 128px;
  }
}
.row-blocks-container {
  display: flex;
  width: 100%;
  margin-inline: 0;
  padding-left: 0;

  .block {
    flex-grow: 0;
    display: block;
    min-width: 0;
    width: auto;
    height: $row_vertical_height;
    background-color: var(--color-text-disabled-theme); // default

    &.v-btn {
      position: relative; // for positionning .downtime-subblock
      margin-inline: ($schedule_gutters / 2);
      padding: 0.5rem 0.25rem 0.5rem 0.75rem;
      border-radius: var(--border-radius-form-elements);

      // Vertical cutting lines
      &.truncated-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px dashed var(--color-text-subtle-theme);
      }
      &.truncated-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 1px dashed var(--color-text-subtle-theme);
      }

      ::v-deep .v-btn__content {
        flex-direction: column;
        align-items: flex-start;
        // to display .downtime-subblock
        position: unset;
        overflow: hidden;
      }

      .string,
      .digits {
        display: inline-block;
        z-index: 2;
        width: 100%;
      }
      .string {
        font-weight: 700;
      }
      .digits {
        margin-top: 0.3rem;
      }

      // this pattern
      .downtime-subblock {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        min-width: 1px;
        height: 100%;
        background-color: var(--color-subtle-container-theme);
        background-image: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent $pattern_space,
          var(--color-grid-background-theme) $pattern_space,
          var(--color-grid-background-theme) ($pattern_stroke_width + $pattern_space)
        );
      }
    }

    /**
     * !block.workShiftId = placeholders required
     * to vertically align blocks with hour ticks
     */
    &.placeholder {
      min-width: 1px;
      margin: 0;
      padding: 0;
      background-color: transparent;
    }
  }

  // responsive
  @media ($wx-sm-min) {
    padding-left: 0;
  }
  @media ($wx-lg-min) {
    width: calc(100% - 3rem);
  }
}

/** -------------------------
 * Unsupported page size alert
 * @media XS
 */
.v-alert {
  &.alert-unsupported-dimension {
    // manage background
    &.v-alert--text:before {
      //background-color: transparent;
    }
    ::v-deep.v-icon {
      color: var(--color-text-subtle-theme);
    }
    // alert contents
    .alert-title,
    .alert-options > .heading {
      margin-bottom: 1rem;
    }

    .alert-title {
      line-height: 1.5; // align verticaly with v-icon
    }
    .alert-options {
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      border-top: 1px solid var(--color-border-theme);

      li {
        display: flex;
        align-items: flex-start;

        .v-icon {
          margin-right: 1rem;
        }
      }
    }
  }
  &.nodata {
    margin-top: 5vh;
  }
}

/**
 * Random pigments to differentiate contents
 * `https://worximity.atlassian.net/l/c/0g0cKHEp`
 */
@for $i from 0 through 8 {
  .block.option-pigment#{$i} {
    background-color: var(--wxData_bgColor#{$i});
  }
}
</style>
